<template>
  <b-container fluid>
    <b-row>
      <b-col cols="10 p-0">
        <TabHeaderInfo
          :title="title"
          :tooltip="tooltip"
          :tooltipId="title.split(' ')[0]"
        >
        </TabHeaderInfo>
      </b-col>
      <b-col cols="2 newButton" >
        <b-button
          :v-can="button.persmission"
          v-if="button.condition"
          class="wh-button no-print"
          variant="primary"
          outline
          @click="button.action(type)"
        >
          {{ button.text }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="cards">
      <b-col
        v-for="form in forms"
        :key="form.id"
       
        sm="12"
        class="card"
      >
        <FormCard
          :form="form"
          :type="type"
          @reloadForms="getFormsMethod"
          @edit-pre-consultation="$emit('edit-pre-consultation', $event)"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'AttendanceList',
  components: {
    FormCard: () => import('@/components/Config/Appointment/FormCard'),
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo')
  },
  props: {
    title: {
      type: String,
      default: 'Consultas personalizadas'
    },
    tooltip: {
      type: String,
      default:
        'Crie e personalize modelo de consulta com os campos e informações que desejar.'
    },
    button: {
      type: Object,
      default: () => ({
        text: 'Nova consulta personalizada',
        condition: true,
        permission: '',
        action: () => {}
      })
    },
    forms: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'ATTENDANCE',
      validator: value => {
        return ['ATTENDANCE', 'EXAM', 'PRE_CONSULTATION'].includes(value)
      }
    },
    getForms: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    getFormsMethod() {
      const camelCaseType = this.type === 'ATTENDANCE' ? 'consultations' :
        this.type
          .toLowerCase()
          .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()) + 's'
      this.getForms(this.type, camelCaseType)
    }
  }
}
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  gap: 1rem;
  border-radius: 8px !important;
  .card {
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 0;
    max-width: calc((100% - (1rem * 3)) / 4);
  }
}
.newButton {
  padding: 20px 0 30px 0;
  justify-content: flex-end;
  display: flex;
}
</style>
